<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-form>
          <v-row dense>
            <v-col class="form-group-header" style="margin-top: 0" cols="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-switch
                    v-on="on"
                    v-model="showPointTotals"
                    style="margin-top: 0; display: inline-block"
                    hide-details
                    @change="changeDisplayType"
                  />
                </template>
                <span>{{ $t('show_total_points') }}</span>
              </v-tooltip>
              <h3 class="text-h6" style="display: inline-block;">
                {{title}}
              </h3>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="userStoryText"
                name="info"
                :label="$t('projects.general.task_list')"
                auto-grow
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="primary"
        text
        @click.native="close"
      >
        {{ $t('close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ProjectUserStoryCopyDialog',

  props: {
    dialog: Boolean,
    sprintIndex: Number,
  },


  data() {
    return {
      showPointTotals: false,
      userStoryText: '',
      userStoryArray: [],
      sprintName: '',
    };
  },

  computed: {
    title() {
      return this.sprintIndex !== null ? `${this.$t('projects.general.sprint_what')} ${this.sprintName} ${this.$t('projects.general.requirements')}` : this.$t('projects.general.unassigned_stories');
    },
  },

  created() {
    this.userStoryText = '';
    this.getUserStories();
    this.changeDisplayType();
  },

  watch: {
    dialog(val) {
      if (val) {
        this.userStoryText = '';
        this.getUserStories();
        this.changeDisplayType();
      }
    },
  },

  methods: {
    getUserStories() {
      if (this.sprintIndex !== null) {
        this.sprintName = this.$store.getters.project.sprints[this.sprintIndex].sprint_name;
        this.userStoryArray = this.$store.getters.project.sprints[this.sprintIndex].user_stories;
      } else {
        this.userStoryArray = this.$store.getters.project.user_stories_not_assigned;
      }
    },

    changeDisplayType() {
      if (this.showPointTotals) {
        this.userStoryTextWithPointTotals();
      } else {
        this.userStoryTextWithPointValues();
      }
    },

    userStoryTextWithPointValues() {
      let userStoryText = '';
      for (const userStory of this.userStoryArray) {
        userStoryText += `${userStory.title}`;
        if (userStory.title[userStory.title.length - 1] !== '.') {
          userStoryText += '. ';
        } else {
          userStoryText += ' ';
        }
        if (userStory.estimated_front_end_points) {
          userStoryText += `F${userStory.estimated_front_end_points} `;
        }
        if (userStory.estimated_back_end_points) {
          userStoryText += `B${userStory.estimated_back_end_points} `;
        }
        if (userStory.estimated_ux_points) {
          userStoryText += `UX${userStory.estimated_ux_points} `;
        }
        if (userStory.estimated_design_points) {
          userStoryText += `D${userStory.estimated_design_points} `;
        }
        userStoryText += '\n';
      }
      this.userStoryText = userStoryText;
    },

    userStoryTextWithPointTotals() {
      let pointTotal;
      let userStoryText = '';
      for (const userStory of this.userStoryArray) {
        pointTotal = 0;
        userStoryText += `${userStory.title}`;
        if (userStory.estimated_front_end_points) {
          pointTotal += +userStory.estimated_front_end_points;
        }
        if (userStory.estimated_back_end_points) {
          pointTotal += +userStory.estimated_back_end_points;
        }
        if (userStory.estimated_ux_points) {
          pointTotal += +userStory.estimated_ux_points;
        }
        if (userStory.estimated_design_points) {
          pointTotal += +userStory.estimated_design_points;
        }
        if (userStory.title[userStory.title.length - 1] !== '.') {
          userStoryText += '.';
        }
        userStoryText += ` Viso: ${pointTotal}.\n`;
      }
      this.userStoryText = userStoryText;
    },

    close() {
      this.$store.state.projects.userStoryCopyDialog = false;
    },
  },
};
</script>
