<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <app-form-attachments
            :attachments="item.attachments"
            @deleteAttachment="deleteAttachment"
          />

          <v-col cols="12">
            <v-select
              v-model="item.sprint_id"
              :items="$store.getters.project.sprints"
              :error-messages="backendErrors.sprint_id"
              :disabled="$store.getters.projectPermission !== 'creator'"
              item-text="sprint_name"
              item-value="id"
              name="sprint_id"
              :label="$t('projects.labels.assigned_to_sprint')"
              clearable
              @blur="clearErrors('sprint_id')"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="item.title"
              :error-messages="backendErrors.title"
              name="title"
              :label="getRequiredFieldLabel($t('title'))"
              required
              @blur="clearErrors('title')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="item.info"
              :error-messages="backendErrors.info"
              :label="$t('description')"
              append-icon="mdi-plus"
              rows="1"
              auto-grow
              @click:append="descriptionDialog = true"
              @blur="clearErrors('info')"
            />
          </v-col>

          <!--            <v-col v-if="index > -1" cols="12">-->
          <!--              <v-subheader class="pa-0">-->
          <!--                {{ $t('tasks') }}:-->
          <!--                <v-tooltip bottom>-->
          <!--                  <template v-slot:activator="{ on }">-->
          <!--                    <v-btn-->
          <!--                      v-on="on"-->
          <!--                      icon-->
          <!--                      @click.stop="newTask"-->
          <!--                    >-->
          <!--                      <v-icon>mdi-plus</v-icon>-->
          <!--                    </v-btn>-->
          <!--                  </template>-->
          <!--                  <span>{{ $t('projects.controls.create_task') }}</span>-->
          <!--                </v-tooltip>-->
          <!--              </v-subheader>-->
          <!--              <app-project-tasks-->
          <!--                v-if="item.tasks && item.tasks.length > 0"-->
          <!--                :sprintIndex="$store.state.projects.sprintIndex"-->
          <!--                :userStoryIndex="index"-->
          <!--                :items="item.tasks"-->
          <!--              />-->
          <!--            </v-col>-->

          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="item.status"
              :error-messages="backendErrors['status']"
              :label="$t('status')"
              class="mt-0"
              @change="clearErrors('status')"
            >
              <v-radio
                v-for="status in $store.state.projects.userStoryStatuses"
                :key="status.value"
                :label="status.text"
                :value="status.value"
              />
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <app-file-select
              :dialog="dialog"
              @fileSelected="setFile"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="canEdit">
        <v-btn
          v-if="index > -1"
          :disabled="requestPending"
          color="primary"
          text
          @click.native="deleteUserStory"
        >
          {{ $t('delete') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          text
          @click.native="close"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="requestPending"
          color="primary"
          type="submit"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer/>
        <v-btn
          text
          @click.native="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>

      <!-- Project user story description modal -->
      <v-dialog
        v-model="descriptionDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        max-width="700px"
        transition="slide-y-reverse-transition"
        scrollable
      >
        <app-project-user-story-description-form
          :dialog="descriptionDialog"
          :current-description="item.info"
          :user-story-id="item.id"
          @descriptionUpdated="descriptionUpdated"
          @dialogClosed="descriptionDialog = false"
        />
      </v-dialog>
    </v-card>
  </v-form>
</template>

<script>
import FileSelect from '../form-fields/FileSelect.vue';
import ProjectUserStoryDescriptionForm from './ProjectUserStoryDescriptionForm.vue';
// import ProjectTasks from './ProjectTasks.vue';
import FormAttachments from '../FormAttachments.vue';
import formMixin from '../mixins/form-mixin';
import dateUtilsMixin from '../mixins/date-utils-mixin';
import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../../../util/event-bus';

export default {
  name: 'ProjectUserStoryForm',

  components: {
    appFileSelect: FileSelect,
    appProjectUserStoryDescriptionForm: ProjectUserStoryDescriptionForm,
    // appProjectTasks: ProjectTasks,
    appFormAttachments: FormAttachments,
  },

  mixins: [formMixin, dateUtilsMixin],

  data() {
    return {
      apiRoute: 'api/project-user-stories/',
      attachedFiles: [],
      descriptionDialog: false,
      agilePoints: [null, '0.5', '1', '2', '3', '5', '8', '13', '20', '40'],
      backendErrors: {
        title: [],
        sprint_id: [],
        info: [],
        user_ids: [],
        status: [],
        assigned_to: [],
        attachments: [],
        estimated_ux_points: [],
        estimated_design_points: [],
        estimated_front_end_points: [],
        estimated_back_end_points: [],
      },
    };
  },

  computed: {
    formTitle() {
      if (this.index === -1) {
        return this.$t('projects.modal_titles.new_story');
      } if (this.canEdit) {
        return this.$t('projects.modal_titles.edit_story');
      }
      return this.$t('projects.modal_titles.preview_story');
    },

    canEdit() {
      if (this.index === -1) {
        return true;
      } if (this.$store.getters.projectPermission === 'creator') {
        return true;
      }
      return this.item.created_by === this.$store.getters.currentUser.id && !this.item.sprint_id;
    },
  },

  watch: {
    descriptionDialog(val) {
      if (this.$store.getters.isTouchDevice) {
        if (val) window.location.hash += '#another-modal';
      }
    },
  },

  methods: {
    newTask() {
      this.$store.dispatch('newTask', {
        userStory: this.item,
        userStoryIndex: this.index,
        sprintIndex: this.$store.state.projects.sprintIndex,
      });
    },

    deleteUserStory() {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, this.$t('projects.confirmations.remove_story'));
      this.$eventBus.$on(CONFIRM_DIALOG_CLOSED, (confirmed) => {
        if (confirmed) {
          this.$http.delete(`api/project-user-stories/${this.item.id}`).then(() => {
            this.$store.dispatch('deleteUserStory', {
              sprintIndex: this.$store.state.projects.sprintIndex,
              index: this.index,
            });
            this.close();
          });
        }
        this.$eventBus.$off(CONFIRM_DIALOG_CLOSED);
      });
    },

    deleteAttachment(event) {
      this.$http.delete(`api/project-attachment/${event.file.id}`).then(() => {
        this.item.attachments.splice(event.index, 1);
        this.$store.dispatch('toggleUserStoryStatus', {
          sprintIndex: this.$store.state.projects.sprintIndex,
          index: this.index,
          item: this.item,
        });
      });
    },

    descriptionUpdated(event) {
      this.$set(this.item, 'info', event);
      this.$store.commit('updateUserStoryDescription', event);
      this.descriptionDialog = false;
    },

    prepareForm() {
      this.attachedFiles = [];
    },

    save() {
      this.requestPending = true;
      if (this.index > -1) {
        this.$http.put(`${this.apiRoute}${this.item.id}`, this.item).then((response) => {
          this.$store.dispatch('updateUserStory', response.data.data);
          this.uploadAttachments(response.data.data);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      } else {
        this.$http.post(`${this.apiRoute}`, this.item).then((response) => {
          this.$store.dispatch('createUserStory', response.data.data);
          this.uploadAttachments(response.data.data);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      }
    },

    uploadAttachments(userStory) {
      if (this.attachedFiles.length > 0) {
        const requestBody = new FormData();
        [...this.attachedFiles].forEach((file) => {
          requestBody.append('file[]', file, file.name);
        });
        requestBody.append('project_user_story_id', userStory.id);
        requestBody.append('project_id', userStory.project_id);
        this.$http.post('api/project-attachment', requestBody, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
          if (!userStory.attachments) {
            userStory.attachments = [];
          }
          response.data.forEach((file) => {
            userStory.attachments.push(file);
          });
          this.$store.dispatch('addUserStoryAttachments', userStory);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      }
    },

    updateStatus(status) {
      this.$http.put(`${this.apiRoute}${this.item.id}/status`, { status }).then((response) => {
        this.item.status = response.data.status;
        this.$store.dispatch('updateUserStoryStatus', this.item);
      }).catch((error) => {
        this.handleBackendErrors(error);
      });
    },

    setFile(event) {
      this.attachedFiles = event;
    },

    close(name) {
      this.$store.state.projects.userStoryDialog = false;
    },
  },
};
</script>

<style scoped>
  .v-chip {
    margin: 0 2px 4px 2px;
    width: 36px;
    justify-content: center;
  }
</style>
