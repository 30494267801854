<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-form>
          <v-row dense>
            <v-col class="form-group-header" style="margin-top: 0" cols="12">
              <h3 class="text-h6">{{ $t('projects.general.stories_movement') }}</h3>
            </v-col>

            <v-col cols="12">
              <app-autocomplete
                :searchRoute="projectSearchRoute"
                :item="project"
                :errorMessages="backendErrors.project_id"
                :labelText="$t('projects.labels.choose_project')"
                textField="title"
                @itemSelected="setProject"
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="sprint_id"
                :disabled="!project.id || sprints.length === 0"
                :error-messages="backendErrors.sprint_id"
                :items="sprints"
                item-text="sprint_name"
                item-value="id"
                :label="$t('projects.labels.choose_sprint')"
                name="sprint_id"
                clearable
                @blur="clearErrors('sprint_id')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        text
        @click.native="close"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click.native="save"
        :disabled="requestPending"
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Autocomplete from '../form-fields/Autocomplete.vue';
import formMixin from '../mixins/form-mixin';
import eventBus, { OPEN_SNACKBAR } from '../../../util/event-bus';

export default {
  name: 'ProjectUserStoryMoveForm',

  components: {
    appAutocomplete: Autocomplete,
  },

  mixins: [formMixin],

  data() {
    return {
      project: {},
      sprint_id: null,
      sprints: [],
      projectSearchRoute: 'api/projects/find/',
      userStoryMoveRoute: 'api/project-user-stories/move',
      backendErrors: {
        project_id: [],
        sprint_id: [],
      },
    };
  },

  methods: {
    save() {
      const userStoryIDs = [];
      for (const id in this.$store.getters.selectedUserStories) {
        if (this.$store.getters.selectedUserStories[id]) {
          userStoryIDs.push(+id);
        }
      }
      const requestBody = {
        project_id: this.project.id,
        sprint_id: this.sprint_id,
        user_story_ids: userStoryIDs,
      };
      this.requestPending = true;
      this.$http.post(this.userStoryMoveRoute, requestBody).then((response) => {
        this.$store.commit('moveSelectedUserStories', response.data.data);
        this.$store.commit('clearSelectedUserStories');
        this.$store.state.projects.selectingUserStories = false;
        eventBus.$emit(OPEN_SNACKBAR, this.$t('projects.general.stories_moved'));
        this.close();
      }).catch((error) => {
        this.handleBackendErrors(error);
      });
    },

    prepareForm() {
      this.project = this.$store.getters.project;
      this.sprints = this.project.sprints;
      const sprintCount = this.project.sprints.length;
      if (sprintCount > 0) {
        this.sprint_id = this.project.sprints[sprintCount - 1].id;
      } else {
        this.sprint_id = null;
      }
    },

    setProject(project) {
      if (project) {
        this.project = project;
        this.getProjectSprints(project.id);
      } else {
        this.project = null;
        this.sprints = [];
      }
    },

    getProjectSprints(projectID) {
      this.$http.get(`api/project-sprints?project_id=${projectID}`).then((response) => {
        this.sprints = [];
        for (const sprint of response.data) {
          if (sprint.status !== 'closed') {
            this.sprints.push(sprint);
          }
        }
      });
    },

    close() {
      this.$store.state.projects.userStoryMoveDialog = false;
    },
  },
};
</script>
