<template>
  <span>
    <v-btn
      v-if="attachments.length === 1"
      class="ma-0"
      icon
      @click.stop="$openFile(attachments[0])"
    >
      <v-icon>mdi-paperclip</v-icon>
    </v-btn>
    <v-menu
      v-else-if="attachments.length > 1"
      bottom
      offset-y
      @click.native.stop
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="ma-0" icon @click.stop>
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(attachment) in attachments"
          :key="attachment.id"
          @click="$openFile(attachment)"
        >
          <v-list-item-action style="min-width: 38px;">
            <v-icon class="mr-3">mdi-open-in-new</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{ attachment.file_name ? attachment.file_name : this.$t('projects.labels.file_without_name') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
export default {
  name: 'ListAttachments',

  props: {
    attachments: Array,
  },
};
</script>
