<template>
  <div class="pt-3" :class="{'mr-2': $vuetify.breakpoint.mdAndUp && !sprintId, 'mb-3': !sprintId}">
    <draggable
      v-model="userStories"
      :disabled="$store.getters.isTouchDevice || $store.getters.projectPermission !== 'creator'"
      :move="checkPermissions"
      :scrollSensitivity="100"
      :scrollSpeed="20"
      group="userStories"
      style="height: 100%; z-index: 30"
      scroll
    >
      <template v-if="userStories && userStories.length > 0">
        <div
          v-for="(item, index) in userStories"
          :key="item.id"
          class="story-row"
          @click="editUserStory(item, index)"
        >
          <v-checkbox
            v-if="$store.state.projects.selectingUserStories"
            v-model="$store.state.projects.selectedUserStories[item.id]"
            class="clickable ma-0 pa-0"
            hide-details
            @click.stop="selectUserStory(item, index)"
          />
          <span :class="{ 'completed': item.status === 'done' }">
            {{ index + 1 }}. {{ item.title }}
          </span>
          <template v-if="!$store.getters.isTouchDevice && (item.info || (item.tasks && item.tasks.length > 0))">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-1" v-on="on">
                  mdi-file-document
                </v-icon>
              </template>
<!--              <template v-if="item.tasks && item.tasks.length > 0">-->
<!--                <pre style="font: inherit">-->
<!--                  <span :style="{'color': $vuetify.theme.info}">{{ $t('projects.general.tasks') }}:</span>-->
<!--                </pre>-->
<!--                <pre v-for="(task, taskIndex) in item.tasks" style="font: inherit">-->
<!--                  {{ taskIndex + 1 }}. {{ task.title }}-->
<!--                </pre>-->
<!--                <span v-if="item.info" style="white-space: pre-line">{{ '\n' }}</span>-->
<!--              </template>-->
              <span v-if="item.info" style="white-space: pre-line">
                {{ item.info }}
              </span>
            </v-tooltip>
          </template>
          <app-attachments :attachments="item.attachments"/>
          <v-spacer/>
          <BaseChipSelect
            :value="item.status"
            :items="$store.state.projects.userStoryStatuses"
            @change="updateUserStoryStatus(item, index, $event)"
          />
        </div>
      </template>

      <div v-else class="text-center pa-3">
        <span v-if="sprintId">{{ $t('projects.general.no_assigned_stories') }}.</span>
        <span v-else>{{ $t('projects.general.no_unassigned_stories') }}.</span>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Attachments from '../ListAttachments.vue';
import BaseChipSelect from '../../base/BaseChipSelect';
import eventBus, { OPEN_SNACKBAR } from '../../../util/event-bus';

export default {
  name: 'ProjectUserStories',

  components: {
    BaseChipSelect,
    draggable,
    appAttachments: Attachments,
  },

  props: {
    items: Array,
    sprintIndex: Number,
    sprintId: Number,
  },

  data() {
    return {
      statusColors: {
        closed: 'success',
        in_progress: 'yellow darken-1',
        ready_for_test: 'info',
        null: 'error',
      },
      showCompletedID: null,
      completionist: null,
      pointHoverTimer: null,
      loading: true,
      userStoryApiRoute: 'api/project-user-stories/',
      userStories: [],
      requestPending: false,
    };
  },

  watch: {
    items(val) {
      this.userStories = val;
    },

    userStories(newValue, oldValue) {
      if (this.items !== this.userStories) {
        this.$store.dispatch('updateUserStories', {
          sprintIndex: this.sprintIndex,
          items: newValue,
        }).then(() => {
          const movedItems = this.findDifference(newValue, oldValue);
          const userStoryOrder = [];
          this.userStories.forEach(item => userStoryOrder.push(item.id));
          if (movedItems && movedItems.length > 0) {
            this.moveUserStories(oldValue, newValue, movedItems, userStoryOrder);
          } else {
            this.reorderUserStories(oldValue, newValue, userStoryOrder);
          }
        });
      }
    },
  },

  created() {
    this.userStories = this.items;
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },

  methods: {
    editUserStory(item, index) {
      this.$store.dispatch('editUserStory', {
        item,
        index,
        sprintIndex: this.sprintIndex,
      });
    },

    moveUserStories(oldValue, newValue, movedItems, userStoryOrder) {
      const movedCount = movedItems ? movedItems.length : 0;
      const usoCount = userStoryOrder ? userStoryOrder.length : 0;
      for (let i = 0; i < movedCount; i++) {
        for (let j = 0; j < usoCount; j++) {
          if (userStoryOrder[j] === movedItems[i].id) {
            const insertAfter = j === 0 ? {} : { insert_after_user_story_id: userStoryOrder[j - 1] };
            if (this.sprintIndex || this.sprintIndex === 0) {
              this.addUserStoryToSprint(oldValue, movedItems[i].id, insertAfter);
            } else {
              this.removeUserStoryFromSprint(oldValue, movedItems[i].id, insertAfter);
            }
          }
        }
      }
    },

    addUserStoryToSprint(oldValue, id, insertAfter) {
      this.$http.put(`api/project-user-stories/${id}/add-to-sprint/${this.sprintId}`, insertAfter).catch(() => {
        this.$store.dispatch('updateUserStories', {
          sprintIndex: this.sprintIndex,
          items: oldValue,
        });
      });
    },

    removeUserStoryFromSprint(oldValue, id, insertAfter) {
      this.$http.put(`api/project-user-stories/${id}/remove-from-sprint`, insertAfter).catch(() => {
        this.$store.dispatch('updateUserStories', {
          sprintIndex: this.sprintIndex,
          items: oldValue,
        });
      });
    },

    reorderUserStories(oldValue, newValue, order) {
      const requestBody = { user_story_ids: order };
      if (newValue && newValue.length > 0) {
        this.$http.post('api/project-user-stories/reorder', requestBody).catch(() => {
          this.$store.dispatch('updateUserStories', {
            sprintIndex: this.sprintIndex,
            items: oldValue,
          });
        });
      }
    },

    findDifference(array1, array2) {
      if (array1 && array2) {
        return array1.filter(i => array2.indexOf(i) < 0);
      }
      return null;
    },

    checkPermissions() {
      return this.$store.getters.projectPermission === 'creator';
    },

    selectUserStory(item, index) {
      this.$store.commit('selectUserStory', {
        id: item.id,
        storyIndex: index,
        sprintIndex: this.sprintIndex,
      });
    },

    updateUserStoryStatus(item, index, newStatus) {
      const payload = { ...item, status: newStatus };
      this.$http.put(`api/project-user-stories/${item.id}`, payload).then((response) => {
        this.$store.dispatch('toggleUserStoryStatus', {
          sprintIndex: this.sprintIndex,
          index,
          item: response.data.data,
        });
      }).catch(() => {
        eventBus.$emit(OPEN_SNACKBAR, this.$t('user_story_status_update_failed'));
      });
    },
  },
};
</script>

<style scoped>
  pre {
    white-space: pre-line;
  }

  .story-row {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px 12px;
    min-height: 40px;
  }

  .v-chip {
    margin: 2px 1px;
    justify-content: center;
  }

  .theme--light .story-row:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
  }
  .theme--light .story-row:hover {
    background: #eee;
  }

  .name-tooltip {
    top: 0;
    right: 0;
  }
</style>
