<template>
  <p v-if="items && items.length === 0" class="text-center">
    {{ $t('projects.general.no_sprints_present_yet') }}.
  </p>
  <v-expansion-panels v-else v-model="expanded" popout multiple>
    <app-project-sprint
      v-for="(sprint, sprintIndex) in items"
      :key="sprint.id"
      :sprint-index="sprintIndex"
      :sprint="sprint"
    />
  </v-expansion-panels>
</template>

<script>
import ProjectSprint from './ProjectSprint.vue';

export default {
  name: 'ProjectSprints',

  components: {
    appProjectSprint: ProjectSprint,
  },

  props: {
    items: Array,
  },

  data() {
    return {
      expanded: [],
    };
  },

  created() {
    this.expanded = [];
    this.items.forEach((_, i) => {
      this.expanded.push(i);
    });
  },
};
</script>
