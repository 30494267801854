<template>
  <v-expansion-panel class="mb-3" @click="loadSprintUserStories">
    <v-progress-linear
      v-if="showProgress && sprint.notLoaded"
      class="sprint-progress-bar"
      color="primary"
      indeterminate
    />
    <v-expansion-panel-header disable-icon-rotate>
      <div class="text-subtitle-2">
        <div>
          {{ sprint.sprint_name }}
          <span class="grey--text">{{ sprint.starts_at }} - {{ sprint.deadline }}</span>
        </div>
        <div v-if="sprint.user_stories.length > 0">
          {{ $t('projects.general.completed_stories') }}: <span class="grey--text">{{ completedUserStories }}/{{ sprint.user_stories.length }}</span>
        </div>
      </div>
      <template v-slot:actions>
        <div>
          <BaseActionMenu v-if="$store.getters.projectPermission === 'creator'" :actions="actions"/>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :id="`sprint-${sprintIndex}`" class="sprint-panel mt-0" expand-icon="">
      <app-project-user-stories
        v-show="!sprint.notLoaded"
        :items="sprint.user_stories"
        :sprintIndex="sprintIndex"
        :sprintId="sprint.id"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ProjectUserStories from './ProjectUserStories.vue';
import BaseActionMenu from '../../base/BaseActionMenu';
import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../../../util/event-bus';

export default {
  name: 'ProjectSprint',

  components: {
    BaseActionMenu,
    appProjectUserStories: ProjectUserStories,
  },

  props: {
    sprint: Object,
    sprintIndex: Number,
  },

  data() {
    return {
      actions: [
        {
          callback: () => this.addUserStory(),
          label: this.$t('projects.general.add_user_story'),
          icon: 'mdi-plus',
        },
        {
          callback: () => this.editItem(),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: () => this.deleteItem(),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      showProgress: false,
      apiRoute: 'api/project-sprints/',
    };
  },

  computed: {
    completedUserStories() {
      let completedUserStories = 0;
      const count = this.sprint.user_stories.length;
      for (let i = 0; i < count; i++) {
        if (this.sprint.user_stories[i].status === 'done') {
          completedUserStories++;
        }
      }
      this.showProgress = false;
      return completedUserStories;
    },
  },

  methods: {
    addUserStory() {
      this.$store.dispatch('newUserStory', {
        sprint: this.sprint,
        sprintIndex: this.sprintIndex,
      });
    },

    loadSprintUserStories() {
      if (this.sprint.notLoaded) {
        this.showProgress = true;
        setTimeout(() => {
          this.$store.dispatch('loadSprintUserStories', {
            sprintIndex: this.sprintIndex,
            sprintID: this.sprint.id,
          });
        }, 1000);
      }
    },

    editItem() {
      this.$store.dispatch('editSprint', {
        item: this.sprint,
        index: this.sprintIndex,
      });
    },

    deleteItem() {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, this.$t('projects.confirmations.remove_sprint'));
      this.$eventBus.$on(CONFIRM_DIALOG_CLOSED, (confirmed) => {
        if (confirmed) {
          this.$http.delete(`${this.apiRoute}${this.sprint.id}`).then(() => {
            this.$store.dispatch('deleteSprint', this.sprintIndex);
          });
        }
        this.$eventBus.$off(CONFIRM_DIALOG_CLOSED);
      });
    },
  },
};
</script>

<style scoped>

</style>
