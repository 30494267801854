<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: menu }">
      <v-tooltip
        :disabled="!tooltip"
        bottom
      >
        <template v-slot:activator="{ on: tooltip }">
          <v-chip
            v-on="{ ...menu, ...tooltip }"
            :color="selectedItem.color"
            class="my-1"
            small
            @click.stop
          >
            {{ selectedItem.text }}
          </v-chip>
        </template>
        <span style="white-space: pre-line">
          {{ tooltip }}
        </span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-for="item in items"
        :key="item.value"
        @click="$emit('change', item.value)"
      >
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseChipSelect',

  props: {
    model: {
      prop: 'value',
      event: 'change',
    },
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },

  computed: {
    selectedItem() {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].value === this.value) {
          return this.items[i];
        }
      }
      return {};
    },
  },
};
</script>

<style scoped>

</style>
