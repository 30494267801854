<template>
  <v-col v-if="attachments && attachments.length > 0" cols="12">
    <v-subheader class="pa-0" style="height: 20px">
      {{ $t('projects.general.attached_files') }}:
    </v-subheader>
    <v-chip
      v-for="(file, index) of attachments"
      :key="file.id"
      class="clickable"
      style="margin: 1px"
      close
      @click="$openFile(file)"
      @click:close="confirmAttachmentDelete(file, index)"
    >
      {{ file.file_name }}
    </v-chip>
  </v-col>
</template>

<script>
import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../../util/event-bus';

export default {
  name: 'FormAttachments',

  props: {
    attachments: Array,
  },

  methods: {
    confirmAttachmentDelete(file, index) {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, this.$t('projects.confirmations.remove_file'));
      eventBus.$on(CONFIRM_DIALOG_CLOSED, (confirmed) => {
        if (confirmed) {
          this.$emit('deleteAttachment', {
            file,
            index,
          });
        }
        eventBus.$off(CONFIRM_DIALOG_CLOSED);
      });
    },
  },
};
</script>
