<template>
  <v-container v-if="$store.getters.project.id" class="pa-0" fluid>
    <v-row dense>
      <v-col
        md="6"
        cols="12"
        order-md="2"
      >
        <v-row justify="space-between" align="center" dense class="px-3">
          <v-menu
            z-index="50"
            offset-y
            @click.native.stop
          >
            <template v-slot:activator="{ on: menu }">
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-on="{ ...tooltip, ...menu }"
                    icon
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('projects.general.shown_sprints_statuses') }}
                </span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item disabled>
                <v-list-item-action>
                  <v-checkbox
                    v-model="$store.state.projects.showStartedSprints"
                    disabled
                  />
                </v-list-item-action>
                <v-list-item-title>
                  {{ $t('projects.controls.show_started_sprints') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="toggleClosedSprints">
                <v-list-item-action>
                  <v-checkbox
                    :value="$store.state.projects.showClosedSprints"
                    @input="toggleClosedSprints"
                  />
                </v-list-item-action>
                <v-list-item-title>
                  {{ $t('projects.controls.show_closed_sprints') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-subheader class="pa-0">
            {{ $t('projects.general.sprints') }}
            <v-tooltip v-if="$store.getters.projectPermission === 'creator'" open-delay="500" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="newSprint"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('projects.modal_titles.new_sprint') }}
              </span>
            </v-tooltip>
          </v-subheader>
          <div>
            <v-tooltip v-if="$store.getters.projectPermission === 'creator'" bottom open-delay="500">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click.stop="toggleUserStorySelection"
                >
                  <v-icon :color="$store.state.projects.selectingUserStories ? 'primary' : ''">
                    {{ $store.state.projects.selectingUserStories ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $store.state.projects.selectingUserStories ? $t('projects.controls.turn_off') : $t('projects.controls.turn_on') }} {{ $t('projects.general.user_stories_choice') }}
              </span>
            </v-tooltip>
          </div>
        </v-row>
        <app-project-sprints
          :items="$store.getters.project.sprints"
        />
      </v-col>

      <v-col
        v-if="$store.getters.settings.showUnassignedUserStories"
        md="6"
        cols="12"
      >
        <v-row justify="center" dense>
          <v-subheader class="pa-0">
            {{ $t('projects.general.unassigned_requirements') }}
            <v-tooltip v-if="$store.getters.projectPermission === 'creator'" open-delay="500" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="newUserStory"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('projects.general.add_user_story') }}
              </span>
            </v-tooltip>
          </v-subheader>
        </v-row>
        <app-project-user-stories
          :items="$store.getters.project.user_stories_not_assigned"
          :sprintIndex="null"
        />
      </v-col>
    </v-row>

    <!-- Project User Story modal -->
    <v-dialog
      v-model="$store.state.projects.userStoryDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900px"
      transition="slide-y-reverse-transition"
      scrollable
      persistent
    >
      <app-project-user-story-form
        :dialog="$store.state.projects.userStoryDialog"
        :index="$store.getters.editedIndex"
        :item="$store.state.projects.editedItem"
      />
    </v-dialog>

    <!-- Project user story move modal -->
    <v-dialog
      v-model="$store.state.projects.userStoryMoveDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      transition="slide-y-reverse-transition"
      scrollable
      persistent
    >
      <app-project-user-story-move-form
        :dialog="$store.state.projects.userStoryMoveDialog"
      />
    </v-dialog>

    <!-- Project Bulk Insert modal -->
    <v-dialog
      v-model="$store.state.projects.bulkInsertDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      transition="slide-y-reverse-transition"
      scrollable
      persistent
    >
      <app-project-bulk-insert-form
        :dialog="$store.state.projects.bulkInsertDialog"
        :index="$store.getters.editedIndex"
        :item="$store.state.projects.editedItem"
      />
    </v-dialog>

    <!-- Project Sprint modal -->
    <v-dialog
      v-model="$store.state.projects.sprintDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      transition="slide-y-reverse-transition"
      scrollable
      persistent
    >
      <app-project-sprint-form
        :dialog="$store.state.projects.sprintDialog"
        :index="$store.getters.editedIndex"
        :item="$store.state.projects.editedItem"
      />
    </v-dialog>

    <!-- Project Task modal -->
    <v-dialog
      v-model="$store.state.projects.taskDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      transition="slide-y-reverse-transition"
      scrollable
      persistent
    >
      <app-project-task-form
        :dialog="$store.state.projects.taskDialog"
        :index="$store.state.projects.editedTaskIndex"
        :item="$store.state.projects.editedTask"
      />
    </v-dialog>

    <v-scale-transition origin="center">
      <v-speed-dial
        v-if="showPrimaryActionButton"
        v-model="fab"
        :open-on-hover="!$store.getters.isTouchDevice"
        bottom
        right
        fixed
      >
        <template v-slot:activator>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="primary"
                dark
                fab
                @click="newUserStory"
              >
                <v-icon v-if="fab">mdi-message-reply-text</v-icon>
                <v-icon v-else>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('projects.modal_titles.new_story') }}</span>
          </v-tooltip>
        </template>

        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              small
              @click.stop="newBulkInsert"
            >
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('projects.controls.mass_insertion') }}</span>
        </v-tooltip>

        <v-tooltip v-if="$store.getters.projectPermission === 'creator'" left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              small
              @click.stop="newSprint"
            >
              <v-icon>mdi-run</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('projects.modal_titles.new_sprint') }}</span>
        </v-tooltip>
      </v-speed-dial>
    </v-scale-transition>
  </v-container>
</template>

<script>
import ProjectSprints from './ProjectSprints.vue';
import ProjectSprintForm from './ProjectSprintForm.vue';
import ProjectUserStories from './ProjectUserStories.vue';
import ProjectUserStoryForm from './ProjectUserStoryForm.vue';
import ProjectUserStoryMoveForm from './ProjectUserStoryMoveForm.vue';
import ProjectBulkInsertForm from './ProjectBulkInsertForm.vue';
import ProjectUserStoryCopyDialog from './ProjectUserStoryCopyDialog.vue';
import ProjectTaskForm from './ProjectTaskForm.vue';
import loadingMixin from '../mixins/loading-mixin';

export default {
  name: 'ProjectBacklog',

  mixins: [loadingMixin],

  components: {
    appProjectUserStories: ProjectUserStories,
    appProjectUserStoryForm: ProjectUserStoryForm,
    appProjectBulkInsertForm: ProjectBulkInsertForm,
    appProjectUserStoryMoveForm: ProjectUserStoryMoveForm,
    appProjectTaskForm: ProjectTaskForm,
    appProjectSprints: ProjectSprints,
    appProjectSprintForm: ProjectSprintForm,
  },

  data() {
    return {
      fab: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.loading = false;
      setTimeout(() => {
        this.showPrimaryActionButton = true;
      }, 500);
    });
  },

  destroyed() {
    this.$store.state.selectingUserStories = false;
    this.$store.commit('clearSelectedUserStories');
  },

  methods: {
    newUserStory() {
      if (this.$store.getters.isTouchDevice && this.fab) {
        this.$store.dispatch('newUserStory');
      } else if (!this.$store.getters.isTouchDevice) {
        this.$store.dispatch('newUserStory');
      }
    },

    newBulkInsert() {
      this.fab = false;
      this.$store.dispatch('newBulkInsert');
    },

    newSprint() {
      this.fab = false;
      this.$store.dispatch('newSprint');
    },

    toggleClosedSprints() {
      this.$store.dispatch('toggleClosedSprints');
    },

    toggleUserStorySelection() {
      if (!this.$store.state.projects.selectingUserStories) {
        this.$store.commit('clearSelectedUserStories');
      }
      this.$store.state.projects.selectingUserStories = !this.$store.state.projects.selectingUserStories;
    },
  },
};
</script>
