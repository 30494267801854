<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-form>
          <v-row dense>

            <v-col class="form-group-header" style="margin-top: 0" cols="12">
              <h3 class="text-h6">{{ $t('projects.general.description_update') }}</h3>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="description"
                :error-messages="backendErrors.description"
                name="description"
                :label="getRequiredFieldLabel($t('text'))"
                auto-grow
                @blur="clearErrors('description')"
              />
            </v-col>

          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        text
        @click.native="close"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click.native="save"
        :disabled="requestPending"
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formMixin from '../mixins/form-mixin';

export default {
  name: 'ProjectUserStoryDescriptionForm',

  mixins: [formMixin],

  props: {
    userStoryId: Number,
    currentDescription: String,
  },

  data() {
    return {
      description: '',
      backendErrors: {
        description: [],
      },
    };
  },

  watch: {
    dialog(val) {
      if (!val) {
        if (this.$store.getters.isTouchDevice) {
          if (window.location.hash.endsWith('#another-modal')) {
            return window.history.back();
          }
        }
      }
    },
  },

  // mounted() {
  //   window.addEventListener('hashchange', e => {
  //     if (e.oldURL.endsWith('#another-modal') && !e.oldURL.endsWith('#modal')) {
  //       this.close('another-modal');
  //     }
  //   });
  // },

  methods: {
    prepareForm() {
      this.description = '';
    },

    save() {
      let user;
      if (this.$store.getters.currentUser) {
        user = this.$store.getters.currentUser.person?.full_name;
      } else {
        user = this.$store.getters.currentUser.username;
      }
      let description;
      if (this.currentDescription) {
        description = `\n-\n${user}:\n${this.description}`;
      } else {
        description = `${user}:\n${this.description}`;
      }
      this.requestPending = true;
      this.$http.put(`api/project-user-stories/${this.userStoryId}/update-description`, { description }).then((response) => {
        this.$emit('descriptionUpdated', response.data.data.info);
      }).catch((error) => {
        this.handleBackendErrors(error);
      });
    },
  },
};
</script>
