<template>
  <v-form @submit.prevent="save(apiRoute)">
    <v-card>
      <v-card-title>
        {{ $t('projects.controls.mass_insertion') }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-select
              v-model="item.sprint_id"
              :items="$store.getters.project.sprints"
              :error-messages="backendErrors.sprint_id"
              :disabled="$store.getters.projectPermission !== 'creator'"
              item-text="sprint_name"
              item-value="id"
              name="sprint_id"
              :label="$t('projects.labels.assign_to_sprint')"
              clearable
              @blur="clearErrors('sprint_id')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="item.info"
              :error-messages="backendErrors.info"
              name="info"
              :label="getRequiredFieldLabel($t('projects.general.task_list'))"
              :hint="$t('projects.general.stories_separate_new_line')"
              auto-grow
              required
              @blur="clearErrors('info')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          text
          @click.native="close"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="requestPending"
          color="primary"
          type="submit"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '../mixins/form-mixin';

export default {
  name: 'ProjectBulkInsertForm',

  mixins: [formMixin],

  data() {
    return {
      apiRoute: 'api/project-user-stories/bulk-insert/',
      createAction: 'bulkInsertUserStories',
      backendErrors: {
        info: [],
        sprint_id: [],
      },
    };
  },

  methods: {
    close() {
      this.$store.state.projects.bulkInsertDialog = false;
    },
  },
};
</script>
