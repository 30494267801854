<template>
  <v-form @submit.prevent="save(apiRoute)">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="item.sprint_name"
              :error-messages="backendErrors.sprint_name"
              name="sprint_name"
              :label="getRequiredFieldLabel($t('title'))"
              required
              @blur="clearErrors('sprint_name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="item.status"
              :items="sprintStatusTypes"
              :error-messages="backendErrors.status"
              name="status"
              :label="$t('status')"
              required
              @blur="clearErrors('status')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="item.starts_at"
              :error-messages="backendErrors.starts_at"
              :label="$t('start_date')"
              @blur="clearErrors('starts_at')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="item.deadline"
              :error-messages="backendErrors.deadline"
              :label="$t('end_date')"
              @blur="clearErrors('deadline')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          text
          @click.native="close"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :disabled="requestPending"
          color="primary"
          type="submit"
          text
          @click.native="save(apiRoute)"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '../mixins/form-mixin';
import BaseDatepickerInput from '../../base/BaseDatepickerInput';

export default {
  name: 'ProjectSprintForm',

  components: { BaseDatepickerInput },

  mixins: [formMixin],

  data() {
    return {
      startsAtPicker: false,
      deadlinePicker: false,
      apiRoute: 'api/project-sprints/',
      updatedSprint: {},
      backendErrors: {
        sprint_name: [],
        starts_at: [],
        deadline: [],
        status: [],
      },
    };
  },

  computed: {
    formTitle() {
      return this.$t(`projects.modal_titles.${this.index === -1 ? 'new_sprint' : 'edit_sprint'}`);
    },

    sprintStatusTypes() {
      return [
        { value: 'started', text: this.$t('projects.labels.started') },
        { value: 'closed', text: this.$t('projects.labels.closed') },
      ];
    },
  },

  methods: {
    save(apiRoute) {
      this.requestPending = true;
      if (this.index > -1) {
        this.$http.put(`${apiRoute}${this.item.id}`, this.item).then((response) => {
          this.$store.dispatch('updateSprint', response.data.data);
          this.updatedSprint = response.data.data;
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      } else {
        this.$http.post(`${apiRoute}`, this.item).then((response) => {
          this.$store.dispatch('createSprint', response.data.data);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      }
    },

    close() {
      this.$store.state.projects.sprintDialog = false;
    },
  },
};
</script>
