<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-form>
          <v-row dense>
            <v-col class="form-group-header" style="margin-top: 0" cols="12">
              <h3 class="text-h6">
                {{ formTitle }}
              </h3>
            </v-col>

            <app-form-attachments
              :attachments="item.attachments"
              @deleteAttachment="deleteAttachment"
            />

            <v-col cols="12">
              <v-text-field
                v-model="item.title"
                :error-messages="backendErrors.title"
                name="title"
                :label="getRequiredFieldLabel($t('title'))"
                @blur="clearErrors('title')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="item.type"
                :items="taskTypes"
                :error-messages="backendErrors['type']"
                name="type"
                :label="$t('type')"
                clearable
                @blur="clearErrors('type')"
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="item.description"
                :error-messages="backendErrors.description"
                name="description"
                :label="$t('description')"
                auto-grow
                @blur="clearErrors('description')"
              />
            </v-col>

            <v-col cols="12">
              <app-file-select
                :dialog="dialog"
                @fileSelected="setFile"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-checkbox
                v-model="item.is_private"
                :error-messages="backendErrors.is_private"
                name="is_private"
                class="form-checkbox"
                :label="$t('projects.labels.private')"
                @blur="clearErrors('is_private')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="index > -1"
        :disabled="requestPending"
        color="primary"
        text
        @click.native="deleteTask"
      >
        {{ $t('remove') }}
      </v-btn>
      <v-spacer/>
      <v-btn
        text
        @click.native="close"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        :disabled="requestPending"
        color="primary"
        text
        @click.native="save(apiRoute)"
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formMixin from '../mixins/form-mixin';
import dateUtilsMixin from '../mixins/date-utils-mixin';
import FileSelect from '../form-fields/FileSelect.vue';
import FormAttachments from '../FormAttachments.vue';
import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../../../util/event-bus';

export default {
  name: 'ProjectTaskForm',

  components: {
    appFileSelect: FileSelect,
    appFormAttachments: FormAttachments,
  },

  mixins: [formMixin, dateUtilsMixin],

  data() {
    return {
      apiRoute: 'api/project-tasks/',
      attachedFiles: [],
      backendErrors: {
        title: [],
        type: [],
        status: [],
        file: [],
      },
    };
  },

  computed: {
    formTitle() {
      return this.$t(`projects.modal_titles.${this.index === -1 ? 'new_task' : 'edit_task'}`);
    },

    taskTypes() {
      return [
        { value: 'front_end', text: 'Front end' },
        { value: 'back_end', text: 'Back end' },
        { value: 'ux', text: 'UX' },
        { value: 'design', text: this.$t('projects.general.design') },
      ];
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        if (this.$store.getters.isTouchDevice) {
          if (window.location.hash.endsWith('#another-modal')) {
            return window.history.back();
          }
        }
      }
    },
  },

  mounted() {
    // window.addEventListener('hashchange', e => {
    //   if (e.oldURL.endsWith('#another-modal') && !e.oldURL.endsWith('#modal')) {
    //     this.close('another-modal');
    //   }
    // });
  },

  methods: {
    deleteAttachment(event) {
      this.$http.delete(`api/project-attachment/${event.file.id}`).then(() => {
        this.item.attachments.splice(event.index, 1);
        this.$store.dispatch('updateTaskStatus', {
          sprintIndex: this.$store.state.projects.sprintIndex,
          userStoryIndex: this.$store.state.projects.userStoryIndex,
          index: this.index,
          item: this.item,
        });
      });
    },

    deleteTask() {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, this.$t('projects.confirmations.remove_task'));
      this.$eventBus.$on(CONFIRM_DIALOG_CLOSED, (confirmed) => {
        if (confirmed) {
          this.$http.delete(`api/project-tasks/${this.item.id}`).then(() => {
            this.$store.dispatch('deleteTask', {
              sprintIndex: this.$store.state.projects.sprintIndex,
              userStoryIndex: this.$store.state.projects.editedIndex,
              index: this.index,
            });
            this.close();
          });
        }
        this.$eventBus.$off(CONFIRM_DIALOG_CLOSED);
      });
    },

    save(apiRoute) {
      this.requestPending = true;
      if (this.index > -1) {
        if (!this.item.type) {
          this.item.type = null;
        }
        this.$http.put(`${apiRoute}${this.item.id}`, this.item).then((response) => {
          this.$store.dispatch('updateTask', response.data);
          this.uploadAttachments(response.data);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      } else {
        this.$http.post(`${apiRoute}`, this.item).then((response) => {
          this.$store.dispatch('createTask', response.data);
          this.uploadAttachments(response.data);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      }
    },

    uploadAttachments(task) {
      if (this.attachedFiles.length > 0) {
        const requestBody = new FormData();
        [...this.attachedFiles].forEach((file) => {
          requestBody.append('file[]', file, file.name);
        });
        requestBody.append('project_task_id', task.id);
        requestBody.append('project_id', task.project_id);
        this.$http.post('api/project-attachment', requestBody, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
          if (!task.attachments) {
            task.attachments = [];
          }
          response.data.forEach((file) => {
            task.attachments.push(file);
          });
          this.$store.dispatch('updateTask', task);
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      }
    },

    prepareForm() {
      this.attachedFiles = [];
    },

    setFile(event) {
      this.attachedFiles = event;
    },

    close() {
      this.$store.state.projects.taskDialog = false;
    },
  },
};
</script>
